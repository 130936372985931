:root {
  --amber-200: #fde68a;
  --amber-700: #b45309;
  --amber-900: #78350f;
  --blue-gray200: #e2e8f0;
  --blue-gray900: #0f172a;
  --blue-light700: #0369a1;
  --body-l-font-family: "Roboto-Regular", Helvetica;
  --body-l-font-size: 18px;
  --body-l-font-style: normal;
  --body-l-font-weight: 400;
  --body-l-letter-spacing: 0px;
  --body-l-line-height: 160.0%;
  --body-XL-font-family: "Roboto-Regular", Helvetica;
  --body-XL-font-size: 20px;
  --body-XL-font-style: normal;
  --body-XL-font-weight: 400;
  --body-XL-letter-spacing: 0px;
  --body-XL-line-height: 180.0%;
  --button-l-font-family: "Roboto-Medium", Helvetica;
  --button-l-font-size: 20px;
  --button-l-font-style: normal;
  --button-l-font-weight: 500;
  --button-l-letter-spacing: .5px;
  --button-l-line-height: 24px;
  --button-m-font-family: "Roboto-Medium", Helvetica;
  --button-m-font-size: 16px;
  --button-m-font-style: normal;
  --button-m-font-weight: 500;
  --button-m-letter-spacing: .5px;
  --button-m-line-height: 24px;
  --fuchsia-700: #a21caf;
  --green-700: #15803d;
  --heading-2-font-family: "Roboto-ExtraBold", Helvetica;
  --heading-2-font-size: 56px;
  --heading-2-font-style: normal;
  --heading-2-font-weight: 800;
  --heading-2-letter-spacing: 0px;
  --heading-2-line-height: 110.0%;
  --heading-6-font-family: "Roboto-Bold", Helvetica;
  --heading-6-font-size: 24px;
  --heading-6-font-style: normal;
  --heading-6-font-weight: 700;
  --heading-6-letter-spacing: 0px;
  --heading-6-line-height: 110.0%;
  --pink-700: #be185d;
  --shadow-dark-l: 0px 0px 6px 0px #00000012, 0px 10px 15px -3px #0000001a;
  --white: #fff;
}

.desktop {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  display: flex;
  position: relative;
}

.desktop .scroll-button {
  width: 57px;
  height: 57px;
  position: absolute;
  top: 871px;
  left: 1769px;
}

.desktop .overlap-group {
  border-radius: 50px;
  height: 57px;
  position: relative;
}

.desktop .vector {
  width: 17px;
  height: 26px;
  position: absolute;
  top: 15px;
  left: 20px;
}

.desktop .bg {
  border: 1px solid #000;
  border-radius: 50px;
  width: 57px;
  height: 57px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .max {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 0 50px;
  display: flex;
  position: relative;
}

.desktop .header {
  background-color: #0000;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 94px;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .frame {
  z-index: 1;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  min-width: 1150px;
  padding: 0 140px;
  display: flex;
  position: relative;
}

.desktop .text-wrapper {
  color: #484848;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Volkhov, Helvetica;
  font-size: 52px;
  font-weight: 400;
  line-height: 52px;
  position: relative;
}

.desktop .nav-elements {
  justify-content: space-between;
  align-items: center;
  width: 648px;
  display: flex;
  position: relative;
}

.desktop .div {
  color: #484848;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.desktop .text-wrapper-2 {
  color: #484848;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: PingFang SC-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.desktop .button {
  all: unset;
  box-sizing: border-box;
  width: 154px;
  height: 56px;
  margin-right: -2px;
  position: relative;
}

.desktop .div-wrapper {
  background-color: #000;
  border-radius: 10px;
  width: 152px;
  height: 56px;
  position: relative;
  box-shadow: 0 20px 35px #00000026;
}

.desktop .text-wrapper-3 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 20px;
  left: 59px;
}

.desktop .frame-2 {
  z-index: 0;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 36px;
  width: 100%;
  min-width: 960px;
  max-width: 1280px;
  display: flex;
  position: relative;
}

.desktop .image {
  background-color: #b0bbbd;
  border-radius: 10px;
  flex: 1;
  height: 756px;
  position: relative;
  overflow: hidden;
}

.desktop .img {
  object-fit: cover;
  width: 403px;
  height: 570px;
  position: absolute;
  top: 186px;
  left: 0;
}

.desktop .frame-3 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 37px;
  height: 756px;
  display: flex;
  position: relative;
}

.desktop .images {
  align-self: stretch;
  width: 100%;
  height: 151px;
  position: relative;
}

.desktop .image-2 {
  object-fit: cover;
  width: 398px;
  height: 145px;
  position: absolute;
  top: 0;
  left: 2px;
}

.desktop .text-button {
  align-self: stretch;
  width: 100%;
  height: 382px;
  position: relative;
}

.desktop .frame-4 {
  flex-direction: column;
  align-items: center;
  gap: 6px;
  width: 403px;
  height: 382px;
  display: flex;
  position: relative;
}

.desktop .ultimate {
  width: 181px;
  height: 91px;
  position: relative;
}

.desktop .text-wrapper-4 {
  color: #484848;
  letter-spacing: -3.64px;
  text-align: center;
  white-space: nowrap;
  font-family: Poppins, Helvetica;
  font-size: 91px;
  font-weight: 500;
  line-height: 91px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .text-wrapper-5 {
  -webkit-text-stroke: 1px #484848;
  color: #0000;
  letter-spacing: -10.29px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Poppins, Helvetica;
  font-size: 187px;
  font-weight: 500;
  line-height: 187px;
  position: relative;
}

.desktop .text-wrapper-6 {
  color: #484848;
  letter-spacing: 2px;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.desktop .overlap-group-wrapper {
  all: unset;
  box-sizing: border-box;
  width: 209px;
  height: 56px;
  position: relative;
}

.desktop .overlap-group-2 {
  background-color: #000;
  border-radius: 10px;
  width: 207px;
  height: 56px;
  position: relative;
  box-shadow: 0 20px 35px #00000026;
}

.desktop .text-wrapper-7 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  position: absolute;
  top: 20px;
  left: 71px;
}

.desktop .images-2 {
  background-image: url("image.6c57d5a7.png");
  background-size: 100% 100%;
  flex: 1;
  align-self: stretch;
  width: 100%;
  position: relative;
}

.desktop .image-wrapper {
  background-color: #d9e4ea;
  border-radius: 10px;
  flex: 1;
  height: 756px;
  position: relative;
  overflow: hidden;
}

.desktop .image-3 {
  object-fit: cover;
  width: 303px;
  height: 568px;
  position: absolute;
  top: 188px;
  left: 52px;
}

.desktop .logos {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 200px;
  padding: 10px 0;
  display: flex;
  position: relative;
}

.desktop .bg-2 {
  background-color: #fff;
  flex: 1;
  align-self: stretch;
  width: 100%;
  position: relative;
  box-shadow: 0 20px 52.29px #4444440a;
}

.desktop .logos-2 {
  width: 1280px;
  height: 36px;
  position: absolute;
  top: 82px;
  left: 320px;
}

.desktop .logo {
  width: 184px;
  height: 27px;
  position: absolute;
  top: 3px;
  left: 1096px;
}

.desktop .logo-2 {
  object-fit: cover;
  width: 196px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 540px;
}

.desktop .logo-3 {
  object-fit: cover;
  width: 196px;
  height: 33px;
  position: absolute;
  top: 3px;
  left: 0;
}

.desktop .logo-4 {
  object-fit: cover;
  width: 196px;
  height: 25px;
  position: absolute;
  top: 7px;
  left: 266px;
}

.desktop .logo-5 {
  width: 196px;
  height: 33px;
  position: absolute;
  top: 0;
  left: 808px;
}

.desktop .new-arrivals {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .categories {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 60px;
  width: 100%;
  padding: 30px 0 0;
  display: flex;
  position: relative;
}

.desktop .category {
  flex: 1;
  min-width: 160px;
  max-width: 240px;
  height: 56px;
  position: relative;
}

.desktop .overlap-group-3 {
  background-color: #fafafa;
  border-radius: 10px;
  width: 240px;
  height: 56px;
  position: relative;
}

.desktop .text-wrapper-8 {
  color: #8a8a8a;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 20px;
  left: 103px;
}

.desktop .overlap {
  background-color: #000;
  border-radius: 10px;
  width: 240px;
  height: 56px;
  position: relative;
  box-shadow: 0 20px 35px #00000026;
}

.desktop .text-wrapper-9 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 20px;
  left: 95px;
}

.desktop .products {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 60px;
  width: 100%;
  max-width: 1440px;
  display: flex;
  position: relative;
}

.desktop .product {
  background-color: #fff;
  border-radius: 10px;
  width: 386px;
  height: 438px;
  position: relative;
  box-shadow: 0 40px 90px #0000000f;
}

.desktop .texts {
  width: 342px;
  height: 131px;
  position: absolute;
  top: 271px;
  left: 25px;
}

.desktop .text-wrapper-10 {
  color: #484848;
  letter-spacing: -.24px;
  white-space: nowrap;
  font-family: Poppins, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: 20px;
  position: absolute;
  top: 111px;
  left: 0;
}

.desktop .text-wrapper-11 {
  color: #ff4646;
  letter-spacing: -.12px;
  text-align: right;
  white-space: nowrap;
  font-family: Poppins, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 111px;
  left: 242px;
}

.desktop .element {
  color: #484848;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 68px;
  left: 0;
}

.desktop .span {
  font-weight: 500;
}

.desktop .text-wrapper-12 {
  font-family: PingFang SC-Medium, Helvetica;
  font-weight: 500;
}

.desktop .stars {
  width: 95px;
  height: 19px;
  position: absolute;
  top: 6px;
  left: 241px;
}

.desktop .name-brand {
  width: 51px;
  height: 43px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .text-wrapper-13 {
  color: #8a8a8a;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Poppins, Helvetica;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  position: absolute;
  top: 31px;
  left: 0;
}

.desktop .text-wrapper-14 {
  color: #484848;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .images-3 {
  width: 336px;
  height: 244px;
  position: absolute;
  top: 15px;
  left: 25px;
}

.desktop .text-wrapper-15 {
  color: #484848;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 12px;
  font-weight: 400;
}

.desktop .name-brand-2 {
  width: 60px;
  height: 43px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .images-4 {
  object-fit: cover;
  width: 336px;
  height: 244px;
  position: absolute;
  top: 15px;
  left: 25px;
}

.desktop .name-brand-3 {
  width: 84px;
  height: 43px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .name-brand-4 {
  width: 64px;
  height: 43px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .name-brand-5 {
  width: 113px;
  height: 43px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .text-wrapper-16 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 20px;
  left: 87px;
}

.desktop .container-wrapper {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 30px 120px;
  display: flex;
  position: relative;
}

.desktop .container {
  background-color: var(--white);
  border-radius: 30px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 80px;
  width: 100%;
  max-width: 1280px;
  height: 496px;
  padding: 80px;
  display: flex;
  position: relative;
  box-shadow: 0 20px 25px -5px #0000001a;
}

.desktop .col {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.desktop .section-text {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 520px;
  display: flex;
  position: relative;
}

.desktop .top {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .secondary-headline {
  color: var(--blue-gray900);
  font-family: var(--heading-2-font-family);
  font-size: var(--heading-2-font-size);
  font-style: var(--heading-2-font-style);
  font-weight: var(--heading-2-font-weight);
  letter-spacing: var(--heading-2-letter-spacing);
  line-height: var(--heading-2-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.desktop .list {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.desktop .list-item-with {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.desktop .icon-jam-icons {
  width: 24px;
  height: 24px;
  position: relative;
}

.desktop .text {
  color: var(--blue-gray900);
  font-family: var(--body-XL-font-family);
  font-size: var(--body-XL-font-size);
  font-style: var(--body-XL-font-style);
  font-weight: var(--body-XL-font-weight);
  letter-spacing: var(--body-XL-letter-spacing);
  line-height: var(--body-XL-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2px;
  position: relative;
}

.desktop .text-container-wrapper {
  all: unset;
  border: 2px solid;
  border-color: var(--amber-900);
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 16px;
  display: inline-flex;
  position: relative;
}

.desktop .text-container {
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  display: inline-flex;
  position: relative;
}

.desktop .button-text {
  color: var(--amber-900);
  font-family: var(--button-l-font-family);
  font-size: var(--button-l-font-size);
  font-style: var(--button-l-font-style);
  font-weight: var(--button-l-font-weight);
  letter-spacing: var(--button-l-letter-spacing);
  line-height: var(--button-l-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.desktop .deco-video {
  width: 713px;
  height: 625px;
  position: absolute;
  top: -60px;
  left: 605px;
}

.desktop .overlap-2 {
  height: 625px;
  position: relative;
}

.desktop .overlap-group-4 {
  width: 713px;
  height: 625px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .rectangle {
  background-color: var(--amber-200);
  width: 666px;
  height: 218px;
  position: absolute;
  top: 204px;
  left: -21px;
  transform: rotate(-45deg);
}

.desktop .rectangle-2 {
  background-color: var(--fuchsia-700);
  opacity: .75;
  border-radius: 30px;
  width: 74px;
  height: 74px;
  position: absolute;
  top: 453px;
  left: 458px;
}

.desktop .rectangle-3 {
  background-color: var(--amber-700);
  opacity: .75;
  border-radius: 30px;
  width: 90px;
  height: 90px;
  position: absolute;
  top: 158px;
  left: 55px;
}

.desktop .rectangle-4 {
  background-color: var(--pink-700);
  opacity: .75;
  border-radius: 50px;
  width: 218px;
  height: 218px;
  position: absolute;
  top: 374px;
  left: 495px;
}

.desktop .rectangle-5 {
  background-color: var(--blue-light700);
  opacity: .75;
  border-radius: 50px;
  width: 59px;
  height: 60px;
  position: absolute;
  top: 45px;
  left: 513px;
}

.desktop .screen-desktop {
  flex: 1;
  height: 428px;
  margin-top: -22px;
  margin-bottom: -70px;
  margin-right: -38px;
  position: relative;
}

.desktop .rectangle-6 {
  background-color: var(--green-700);
  opacity: .75;
  border-radius: 50px;
  width: 85px;
  height: 86px;
  position: absolute;
  top: 373px;
  left: 750px;
}

.desktop .section-wrapper {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 0 120px;
  display: flex;
  position: relative;
}

.desktop .section {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 80px;
  width: 100%;
  max-width: 1280px;
  padding: 80px 0;
  display: flex;
  position: relative;
}

.desktop .vector-2 {
  width: 1360px;
  height: 327px;
  position: absolute;
  top: 404px;
  left: 240px;
}

.desktop .row {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .top-wrapper {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 24px;
  display: flex;
  position: relative;
}

.desktop .button-2 {
  all: unset;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  display: inline-flex;
  position: relative;
}

.desktop .button-text-2 {
  color: var(--amber-900);
  font-family: var(--button-m-font-family);
  font-size: var(--button-m-font-size);
  font-style: var(--button-m-font-style);
  font-weight: var(--button-m-font-weight);
  letter-spacing: var(--button-m-letter-spacing);
  line-height: var(--button-m-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.desktop .row-2 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .content-box {
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--blue-gray200);
  box-shadow: var(--shadow-dark-l);
  border-radius: 10px;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.desktop .picture {
  background-image: url("picture-1.b22b0c00.png");
  background-position: 50%;
  background-size: cover;
  flex: none;
  width: 100%;
  position: relative;
}

.desktop .content {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  padding: 32px 32px 0;
  display: flex;
  position: relative;
}

.desktop .title-category {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .title-container {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .title {
  color: var(--blue-gray900);
  font-family: var(--heading-6-font-family);
  font-size: var(--heading-6-font-size);
  font-style: var(--heading-6-font-style);
  font-weight: var(--heading-6-font-weight);
  letter-spacing: var(--heading-6-letter-spacing);
  line-height: var(--heading-6-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.desktop .paragraph {
  color: var(--blue-gray900);
  font-family: var(--body-l-font-family);
  font-size: var(--body-l-font-size);
  font-style: var(--body-l-font-style);
  font-weight: var(--body-l-font-weight);
  letter-spacing: var(--body-l-letter-spacing);
  line-height: var(--body-l-line-height);
  align-self: stretch;
  position: relative;
}

.desktop .buttons-group {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  padding: 16px 32px;
  display: flex;
  position: relative;
}

.desktop .button-text-wrapper {
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 0 16px 0 0;
  display: inline-flex;
  position: relative;
}

.desktop .picture-2 {
  background-image: url("picture-2.ee9c55d4.png");
  background-position: 50%;
  background-size: cover;
  flex: none;
  align-self: stretch;
  width: 100%;
  position: relative;
}

.desktop .picture-3 {
  background-image: url("picture-3.b18d8e9f.png");
  background-position: 50%;
  background-size: cover;
  flex: none;
  align-self: stretch;
  width: 100%;
  position: relative;
}

.desktop .picture-4 {
  background-image: url("picture-4.aa2020e0.png");
  background-position: 50%;
  background-size: cover;
  flex: none;
  align-self: stretch;
  width: 100%;
  position: relative;
}

.desktop .footer {
  background-color: #0000;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .separator {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -1px;
  position: relative;
}

.desktop .data {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 30px;
  width: 100%;
  height: 114px;
  padding: 0 160px;
  display: flex;
  position: relative;
}

.desktop .logo-itema {
  align-self: stretch;
  width: 100%;
  height: 32px;
  margin-right: -2px;
  position: relative;
}

.desktop .items {
  justify-content: space-between;
  align-items: flex-start;
  width: 724px;
  display: flex;
  position: absolute;
  top: 7px;
  left: 876px;
}

.desktop .text-wrapper-17 {
  color: #484848;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.desktop .text-wrapper-18 {
  color: #484848;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: PingFang SC-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.desktop .text-wrapper-19 {
  color: #484848;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Volkhov, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .copyright-text {
  color: #484848;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Poppins, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
  position: relative;
}

/*# sourceMappingURL=index.ce917548.css.map */
