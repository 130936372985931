:root {
  --amber-200: rgba(253, 230, 138, 1);
  --amber-700: rgba(180, 83, 9, 1);
  --amber-900: rgba(120, 53, 15, 1);
  --blue-gray200: rgba(226, 232, 240, 1);
  --blue-gray900: rgba(15, 23, 42, 1);
  --blue-light700: rgba(3, 105, 161, 1);
  --body-l-font-family: "Roboto-Regular", Helvetica;
  --body-l-font-size: 18px;
  --body-l-font-style: normal;
  --body-l-font-weight: 400;
  --body-l-letter-spacing: 0px;
  --body-l-line-height: 160.0000023841858%;
  --body-XL-font-family: "Roboto-Regular", Helvetica;
  --body-XL-font-size: 20px;
  --body-XL-font-style: normal;
  --body-XL-font-weight: 400;
  --body-XL-letter-spacing: 0px;
  --body-XL-line-height: 179.99999523162842%;
  --button-l-font-family: "Roboto-Medium", Helvetica;
  --button-l-font-size: 20px;
  --button-l-font-style: normal;
  --button-l-font-weight: 500;
  --button-l-letter-spacing: 0.5px;
  --button-l-line-height: 24px;
  --button-m-font-family: "Roboto-Medium", Helvetica;
  --button-m-font-size: 16px;
  --button-m-font-style: normal;
  --button-m-font-weight: 500;
  --button-m-letter-spacing: 0.5px;
  --button-m-line-height: 24px;
  --fuchsia-700: rgba(162, 28, 175, 1);
  --green-700: rgba(21, 128, 61, 1);
  --heading-2-font-family: "Roboto-ExtraBold", Helvetica;
  --heading-2-font-size: 56px;
  --heading-2-font-style: normal;
  --heading-2-font-weight: 800;
  --heading-2-letter-spacing: 0px;
  --heading-2-line-height: 110.00000238418579%;
  --heading-6-font-family: "Roboto-Bold", Helvetica;
  --heading-6-font-size: 24px;
  --heading-6-font-style: normal;
  --heading-6-font-weight: 700;
  --heading-6-letter-spacing: 0px;
  --heading-6-line-height: 110.00000238418579%;
  --pink-700: rgba(190, 24, 93, 1);
  --shadow-dark-l: 0px 0px 6px 0px rgba(0, 0, 0, 0.07), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --white: rgba(255, 255, 255, 1);
}
