.desktop {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  position: relative;
}

.desktop .scroll-button {
  height: 57px;
  left: 1769px;
  position: absolute;
  top: 871px;
  width: 57px;
}

.desktop .overlap-group {
  border-radius: 50px;
  height: 57px;
  position: relative;
}

.desktop .vector {
  height: 26px;
  left: 20px;
  position: absolute;
  top: 15px;
  width: 17px;
}

.desktop .bg {
  border: 1px solid;
  border-color: #000000;
  border-radius: 50px;
  height: 57px;
  left: 0;
  position: absolute;
  top: 0;
  width: 57px;
}

.desktop .max {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 0px 50px;
  position: relative;
  width: 100%;
}

.desktop .header {
  align-items: center;
  align-self: stretch;
  background-color: transparent;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 94px;
  position: relative;
  width: 100%;
}

.desktop .frame {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  min-width: 1150px;
  padding: 0px 140px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.desktop .text-wrapper {
  color: #484848;
  font-family: "Volkhov", Helvetica;
  font-size: 52px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 52px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .nav-elements {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 648px;
}

.desktop .div {
  color: #484848;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.desktop .text-wrapper-2 {
  color: #484848;
  font-family: "PingFang SC-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.desktop .button {
  all: unset;
  box-sizing: border-box;
  height: 56px;
  margin-right: -2px;
  position: relative;
  width: 154px;
}

.desktop .div-wrapper {
  background-color: #000000;
  border-radius: 10px;
  box-shadow: 0px 20px 35px #00000026;
  height: 56px;
  position: relative;
  width: 152px;
}

.desktop .text-wrapper-3 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 59px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 20px;
  white-space: nowrap;
}

.desktop .frame-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 36px;
  justify-content: center;
  max-width: 1280px;
  min-width: 960px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.desktop .image {
  background-color: #b0bbbd;
  border-radius: 10px;
  flex: 1;
  flex-grow: 1;
  height: 756px;
  overflow: hidden;
  position: relative;
}

.desktop .img {
  height: 570px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 186px;
  width: 403px;
}

.desktop .frame-3 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 37px;
  height: 756px;
  position: relative;
}

.desktop .images {
  align-self: stretch;
  height: 151px;
  position: relative;
  width: 100%;
}

.desktop .image-2 {
  height: 145px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 398px;
}

.desktop .text-button {
  align-self: stretch;
  height: 382px;
  position: relative;
  width: 100%;
}

.desktop .frame-4 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 6px;
  height: 382px;
  position: relative;
  width: 403px;
}

.desktop .ultimate {
  height: 91px;
  position: relative;
  width: 181px;
}

.desktop .text-wrapper-4 {
  color: #484848;
  font-family: "Poppins", Helvetica;
  font-size: 91px;
  font-weight: 500;
  left: 0;
  letter-spacing: -3.64px;
  line-height: 91px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.desktop .text-wrapper-5 {
  -webkit-text-stroke: 1px #484848;
  color: transparent;
  font-family: "Poppins", Helvetica;
  font-size: 187px;
  font-weight: 500;
  letter-spacing: -10.29px;
  line-height: 187px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.desktop .text-wrapper-6 {
  color: #484848;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.desktop .overlap-group-wrapper {
  all: unset;
  box-sizing: border-box;
  height: 56px;
  position: relative;
  width: 209px;
}

.desktop .overlap-group-2 {
  background-color: #000000;
  border-radius: 10px;
  box-shadow: 0px 20px 35px #00000026;
  height: 56px;
  position: relative;
  width: 207px;
}

.desktop .text-wrapper-7 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 71px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 20px;
  white-space: nowrap;
}

.desktop .images-2 {
  align-self: stretch;
  background-image: url(../../../static/img/image.png);
  background-size: 100% 100%;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.desktop .image-wrapper {
  background-color: #d9e4ea;
  border-radius: 10px;
  flex: 1;
  flex-grow: 1;
  height: 756px;
  overflow: hidden;
  position: relative;
}

.desktop .image-3 {
  height: 568px;
  left: 52px;
  object-fit: cover;
  position: absolute;
  top: 188px;
  width: 303px;
}

.desktop .logos {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 200px;
  justify-content: center;
  padding: 10px 0px;
  position: relative;
  width: 100%;
}

.desktop .bg-2 {
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: 0px 20px 52.29px #4444440a;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.desktop .logos-2 {
  height: 36px;
  left: 320px;
  position: absolute;
  top: 82px;
  width: 1280px;
}

.desktop .logo {
  height: 27px;
  left: 1096px;
  position: absolute;
  top: 3px;
  width: 184px;
}

.desktop .logo-2 {
  height: 32px;
  left: 540px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 196px;
}

.desktop .logo-3 {
  height: 33px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 196px;
}

.desktop .logo-4 {
  height: 25px;
  left: 266px;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 196px;
}

.desktop .logo-5 {
  height: 33px;
  left: 808px;
  position: absolute;
  top: 0;
  width: 196px;
}

.desktop .new-arrivals {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  position: relative;
  width: 100%;
}

.desktop .categories {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 60px;
  justify-content: center;
  padding: 30px 0px 0px;
  position: relative;
  width: 100%;
}

.desktop .category {
  flex: 1;
  flex-grow: 1;
  height: 56px;
  max-width: 240px;
  min-width: 160px;
  position: relative;
}

.desktop .overlap-group-3 {
  background-color: #fafafa;
  border-radius: 10px;
  height: 56px;
  position: relative;
  width: 240px;
}

.desktop .text-wrapper-8 {
  color: #8a8a8a;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 103px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 20px;
  white-space: nowrap;
}

.desktop .overlap {
  background-color: #000000;
  border-radius: 10px;
  box-shadow: 0px 20px 35px #00000026;
  height: 56px;
  position: relative;
  width: 240px;
}

.desktop .text-wrapper-9 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 95px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 20px;
  white-space: nowrap;
}

.desktop .products {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 60px 60px;
  justify-content: center;
  max-width: 1440px;
  position: relative;
  width: 100%;
}

.desktop .product {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 40px 90px #0000000f;
  height: 438px;
  position: relative;
  width: 386px;
}

.desktop .texts {
  height: 131px;
  left: 25px;
  position: absolute;
  top: 271px;
  width: 342px;
}

.desktop .text-wrapper-10 {
  color: #484848;
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: -0.24px;
  line-height: 20px;
  position: absolute;
  top: 111px;
  white-space: nowrap;
}

.desktop .text-wrapper-11 {
  color: #ff4646;
  font-family: "Poppins", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 242px;
  letter-spacing: -0.12px;
  line-height: 20px;
  position: absolute;
  text-align: right;
  top: 111px;
  white-space: nowrap;
}

.desktop .element {
  color: #484848;
  font-family: "Poppins", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 68px;
}

.desktop .span {
  font-weight: 500;
}

.desktop .text-wrapper-12 {
  font-family: "PingFang SC-Medium", Helvetica;
  font-weight: 500;
}

.desktop .stars {
  height: 19px;
  left: 241px;
  position: absolute;
  top: 6px;
  width: 95px;
}

.desktop .name-brand {
  height: 43px;
  left: 0;
  position: absolute;
  top: 0;
  width: 51px;
}

.desktop .text-wrapper-13 {
  color: #8a8a8a;
  font-family: "Poppins", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 12px;
  position: absolute;
  top: 31px;
  white-space: nowrap;
}

.desktop .text-wrapper-14 {
  color: #484848;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.desktop .images-3 {
  height: 244px;
  left: 25px;
  position: absolute;
  top: 15px;
  width: 336px;
}

.desktop .text-wrapper-15 {
  color: #484848;
  font-family: "Poppins", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
}

.desktop .name-brand-2 {
  height: 43px;
  left: 0;
  position: absolute;
  top: 0;
  width: 60px;
}

.desktop .images-4 {
  height: 244px;
  left: 25px;
  object-fit: cover;
  position: absolute;
  top: 15px;
  width: 336px;
}

.desktop .name-brand-3 {
  height: 43px;
  left: 0;
  position: absolute;
  top: 0;
  width: 84px;
}

.desktop .name-brand-4 {
  height: 43px;
  left: 0;
  position: absolute;
  top: 0;
  width: 64px;
}

.desktop .name-brand-5 {
  height: 43px;
  left: 0;
  position: absolute;
  top: 0;
  width: 113px;
}

.desktop .text-wrapper-16 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 87px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 20px;
  white-space: nowrap;
}

.desktop .container-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 30px 120px;
  position: relative;
  width: 100%;
}

.desktop .container {
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
  border-radius: 30px;
  box-shadow: 0px 20px 25px -5px #0000001a;
  display: flex;
  gap: 80px;
  height: 496px;
  justify-content: center;
  max-width: 1280px;
  padding: 80px;
  position: relative;
  width: 100%;
}

.desktop .col {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.desktop .section-text {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 520px;
}

.desktop .top {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.desktop .secondary-headline {
  align-self: stretch;
  color: var(--blue-gray900);
  font-family: var(--heading-2-font-family);
  font-size: var(--heading-2-font-size);
  font-style: var(--heading-2-font-style);
  font-weight: var(--heading-2-font-weight);
  letter-spacing: var(--heading-2-letter-spacing);
  line-height: var(--heading-2-line-height);
  margin-top: -1px;
  position: relative;
}

.desktop .list {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.desktop .list-item-with {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.desktop .icon-jam-icons {
  height: 24px;
  position: relative;
  width: 24px;
}

.desktop .text {
  color: var(--blue-gray900);
  font-family: var(--body-XL-font-family);
  font-size: var(--body-XL-font-size);
  font-style: var(--body-XL-font-style);
  font-weight: var(--body-XL-font-weight);
  letter-spacing: var(--body-XL-letter-spacing);
  line-height: var(--body-XL-line-height);
  margin-top: -2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .text-container-wrapper {
  all: unset;
  align-items: center;
  border: 2px solid;
  border-color: var(--amber-900);
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 16px;
  position: relative;
}

.desktop .text-container {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 0px 16px;
  position: relative;
}

.desktop .button-text {
  color: var(--amber-900);
  font-family: var(--button-l-font-family);
  font-size: var(--button-l-font-size);
  font-style: var(--button-l-font-style);
  font-weight: var(--button-l-font-weight);
  letter-spacing: var(--button-l-letter-spacing);
  line-height: var(--button-l-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .deco-video {
  height: 625px;
  left: 605px;
  position: absolute;
  top: -60px;
  width: 713px;
}

.desktop .overlap-2 {
  height: 625px;
  position: relative;
}

.desktop .overlap-group-4 {
  height: 625px;
  left: 0;
  position: absolute;
  top: 0;
  width: 713px;
}

.desktop .rectangle {
  background-color: var(--amber-200);
  height: 218px;
  left: -21px;
  position: absolute;
  top: 204px;
  transform: rotate(-45deg);
  width: 666px;
}

.desktop .rectangle-2 {
  background-color: var(--fuchsia-700);
  border-radius: 30px;
  height: 74px;
  left: 458px;
  opacity: 0.75;
  position: absolute;
  top: 453px;
  width: 74px;
}

.desktop .rectangle-3 {
  background-color: var(--amber-700);
  border-radius: 30px;
  height: 90px;
  left: 55px;
  opacity: 0.75;
  position: absolute;
  top: 158px;
  width: 90px;
}

.desktop .rectangle-4 {
  background-color: var(--pink-700);
  border-radius: 50px;
  height: 218px;
  left: 495px;
  opacity: 0.75;
  position: absolute;
  top: 374px;
  width: 218px;
}

.desktop .rectangle-5 {
  background-color: var(--blue-light700);
  border-radius: 50px;
  height: 60px;
  left: 513px;
  opacity: 0.75;
  position: absolute;
  top: 45px;
  width: 59px;
}

.desktop .screen-desktop {
  flex: 1;
  flex-grow: 1;
  height: 428px;
  margin-bottom: -70px;
  margin-right: -38px;
  margin-top: -22px;
  position: relative;
}

.desktop .rectangle-6 {
  background-color: var(--green-700);
  border-radius: 50px;
  height: 86px;
  left: 750px;
  opacity: 0.75;
  position: absolute;
  top: 373px;
  width: 85px;
}

.desktop .section-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 0px 120px;
  position: relative;
  width: 100%;
}

.desktop .section {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  max-width: 1280px;
  padding: 80px 0px;
  position: relative;
  width: 100%;
}

.desktop .vector-2 {
  height: 327px;
  left: 240px;
  position: absolute;
  top: 404px;
  width: 1360px;
}

.desktop .row {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
  width: 100%;
}

.desktop .top-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  position: relative;
}

.desktop .button-2 {
  all: unset;
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 12px 0px;
  position: relative;
}

.desktop .button-text-2 {
  color: var(--amber-900);
  font-family: var(--button-m-font-family);
  font-size: var(--button-m-font-size);
  font-style: var(--button-m-font-style);
  font-weight: var(--button-m-font-weight);
  letter-spacing: var(--button-m-letter-spacing);
  line-height: var(--button-m-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .row-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
  width: 100%;
}

.desktop .content-box {
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--blue-gray200);
  border-radius: 10px;
  box-shadow: var(--shadow-dark-l);
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.desktop .picture {
  background-image: url(../../../static/img/picture-1.png);
  background-position: 50% 50%;
  background-size: cover;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.desktop .content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  padding: 32px 32px 0px;
  position: relative;
  width: 100%;
}

.desktop .title-category {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 100%;
}

.desktop .title-container {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}

.desktop .title {
  color: var(--blue-gray900);
  flex: 1;
  font-family: var(--heading-6-font-family);
  font-size: var(--heading-6-font-size);
  font-style: var(--heading-6-font-style);
  font-weight: var(--heading-6-font-weight);
  letter-spacing: var(--heading-6-letter-spacing);
  line-height: var(--heading-6-line-height);
  margin-top: -1px;
  position: relative;
}

.desktop .paragraph {
  align-self: stretch;
  color: var(--blue-gray900);
  font-family: var(--body-l-font-family);
  font-size: var(--body-l-font-size);
  font-style: var(--body-l-font-style);
  font-weight: var(--body-l-font-weight);
  letter-spacing: var(--body-l-letter-spacing);
  line-height: var(--body-l-line-height);
  position: relative;
}

.desktop .buttons-group {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  padding: 16px 32px;
  position: relative;
  width: 100%;
}

.desktop .button-text-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 0px 16px 0px 0px;
  position: relative;
}

.desktop .picture-2 {
  align-self: stretch;
  background-image: url(../../../static/img/picture-2.png);
  background-position: 50% 50%;
  background-size: cover;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.desktop .picture-3 {
  align-self: stretch;
  background-image: url(../../../static/img/picture-3.png);
  background-position: 50% 50%;
  background-size: cover;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.desktop .picture-4 {
  align-self: stretch;
  background-image: url(../../../static/img/picture-4.png);
  background-position: 50% 50%;
  background-size: cover;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.desktop .footer {
  align-items: center;
  align-self: stretch;
  background-color: transparent;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 100%;
}

.desktop .separator {
  align-self: stretch;
  height: 1px;
  margin-top: -1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.desktop .data {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 114px;
  padding: 0px 160px;
  position: relative;
  width: 100%;
}

.desktop .logo-itema {
  align-self: stretch;
  height: 32px;
  margin-right: -2px;
  position: relative;
  width: 100%;
}

.desktop .items {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  left: 876px;
  position: absolute;
  top: 7px;
  width: 724px;
}

.desktop .text-wrapper-17 {
  color: #484848;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.desktop .text-wrapper-18 {
  color: #484848;
  font-family: "PingFang SC-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.desktop .text-wrapper-19 {
  color: #484848;
  font-family: "Volkhov", Helvetica;
  font-size: 32px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.desktop .copyright-text {
  align-self: stretch;
  color: #484848;
  font-family: "Poppins", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
  position: relative;
  text-align: center;
}
